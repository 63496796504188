import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Elite Turf
			</title>
			<meta name={"description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:title"} content={"Elite Turf"} />
			<meta property={"og:description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link">
				Головна
			</Override>
			<Override slot="link2">
				Послуги
			</Override>
			<Override slot="link3">
				Контакти
			</Override>
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,#081c0f 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-2.jpg?v=2024-06-19T12:06:47.265Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Комплексні послуги для футбольних ентузіастів
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				У Elite Turf ми виходимо за рамки простого надання ігрових умов найвищого рівня. Ми пропонуємо низку послуг, спрямованих на покращення ваших футбольних вражень і задоволення потреб наших клієнтів і гостей. Від організації заходів до надання необхідного обладнання Elite Turf — це ваше універсальне місце для всього, що стосується футболу.
			</Override>
			<Override slot="text3" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				align-self="center"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Наш заклад оснащено сучасними зручностями та функціями, які забезпечують комфорт і приємні враження для всіх гравців і глядачів. Ми пишаємося тим, що підтримуємо високі стандарти якості та чистоти, роблячи кожне відвідування Elite Turf задоволенням.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12:06:47.257Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,rgba(4, 8, 12, 0.44) 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Які послуги ми надаємо
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12:06:47.267Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Оренда поля: гнучкі варіанти бронювання для команд і окремих осіб, доступні для погодинних, щоденних або повторюваних слотів.
 Індивідуальна оренда: ідеально підходить для індивідуальних занять або тренувань у невеликих групах.
 Оренда команди: ідеально підходить для командних тренувань, сутичок або товариських матчів.
 Оренда подій: настроювані пакети для турнірів, корпоративних заходів і приватних вечірок.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12:06:47.257Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-3.jpg?v=2024-06-19T12%3A06%3A47.257Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Оренда обладнання: високоякісне футбольне обладнання доступне в оренду, щоб покращити вашу гру.
 Футбольні м’ячі: професійні м’ячі, які відповідають офіційним стандартам розміру та ваги.
 Спорядження для тренувань: конуси, драбини спритності та інші посібники для тренувань, які допоможуть покращити ваші навички.
 Воротарське спорядження: рукавички, щитки та інше спеціальне спорядження для воротарів.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12:06:47.265Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Тренування та тренування: професійні тренерські послуги, які допомагають гравцям покращити свої навички та продуктивність.
 Індивідуальний коучинг: персоналізовані тренінги, адаптовані до ваших потреб і цілей.
 Груповий коучинг: командні тренінги, присвячені тактиці, командній роботі та загальній продуктивності.
 Молодіжні програми: спеціалізовані тренувальні програми для молодих гравців, спрямовані на розвиток основних навичок і любові до гри.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12:06:47.262Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-3.jpg?v=2024-06-19T12%3A06%3A47.262Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Управління подіями: комплексна підтримка для організації та управління футбольними подіями.
 Організація турнірів: наскрізне керування футбольними турнірами, зокрема планування, суддівство та нагородження.
 Корпоративні заходи: настроювані пакети для корпоративних заходів з формування команди та корпоративних турнірів.
 Приватні вечірки: спеціальні пакети для днів народжень, святкувань та інших приватних зустрічей.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12:06:47.253Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-5.jpg?v=2024-06-19T12%3A06%3A47.253Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Інфраструктура: сучасні, доглянуті зручності, щоб забезпечити комфорт для кожного.
 Роздягальні: Чисті та просторі роздягальні, обладнані шафками та душовими.
 Зони для глядачів: зручні зони для відпочинку для друзів і родини, щоб спостерігати за грою.
 Паркінг: достатньо місця для паркування для всіх відвідувачів.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12:06:47.298Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12%3A06%3A47.298Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 2rem"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					І це лише короткий огляд того, що може запропонувати Elite Turf. Щоб дізнатися про повний спектр наших послуг і про те, як ми можемо задовольнити ваші потреби, зв’яжіться з нами. Незалежно від того, чи бажаєте ви орендувати поле, забронювати обладнання чи планувати захід, наша команда тут, щоб допомогти вам. Зв’яжіться з нами сьогодні, і ми допоможемо вам покращити свій ігровий досвід.
				</Text>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});